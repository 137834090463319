<template>
  <div class="user-wrap">
    <div class="user-left">
      <div class="user-title">Forum</div>
      <div class="user-nav">
        <ul>
          <li v-for="(group, index) in userGroups" :key="index" @click="fetchDiscussions(group.id, index)">
            <button class="nav-link" :class="{ active: sideNav[index], 'is-private': group.isPrivate }">
              {{ group.name }} <span v-if="group.isPrivate"></span>
            </button>
            {{ console.log('Rendering group:', group) }}
          </li>
        </ul>
      </div>
      <div class="new-group">
        <a @click="showJoinGroupDialog">+ Join New Group</a>
        <a v-if="userLevel === 2 || userLevel === 3" @click="showBuildGroupDialog">+ Build New Group</a>
        <a v-if="userLevel === 2 || userLevel === 3" @click="ManageClick">+ Manage Group</a>
      </div>
    </div>
    <div class="user-right">
      <div class="content">
        <button class="nav-link" @click="showAddPostDialog">Add Post</button>
        <button class="nav-link" @click="shareGroup">Share Group</button>
        <PostSummary
          v-for="(post, index) in posts"
          :key="index"
          :post="post"
        />
      </div>
    </div>
    <el-dialog v-model="isAddPostDialogVisible" title="Add New Post" width="720">
      <el-card class="box-card" style="width: 670px;">
        <template #header>
          <div class="card-header">
            <span>Add New Post</span>
          </div>
        </template>
        <el-form :model="newPost">
          <el-form-item label="Title">
            <el-input v-model="newPost.title"></el-input>
          </el-form-item>                                                                                      
          <el-form-item label="Topic">
            <el-input v-model="newPost.tags"></el-input>
          </el-form-item>
          <el-form-item label="Content">
            <el-input type="textarea" v-model="newPost.content"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isAddPostDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitPost">Submit</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isJoinGroupDialogVisible" title="Join New Group" width="500px">
      <el-form>
        <el-form-item label="Group Code">
          <el-input v-model="groupCode" placeholder="Enter group code"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isJoinGroupDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="joinGroup">Join Group</el-button>
        </span>
      </template>
    </el-dialog>
    
    <el-dialog v-model="isBuildGroupDialogVisible" title="Build New Group" width="500px">
      <el-form>
        <el-form-item label="Group Name">
          <el-input v-model="newGroupName" maxlength="20" placeholder="Enter group name"></el-input>
          <div style="font-size: 12px; color: #888;">
            {{ groupNameLength }} / 20
          </div>
        </el-form-item>
        <el-form-item label="Group Type">
          <el-radio-group v-model="newGroupType">
            <el-radio label="public">Public</el-radio>
            <el-radio label="private">Private</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isBuildGroupDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="createGroup">Create Group</el-button>
        </span>
      </template>
    </el-dialog>
    
  </div>
</template>


<script>
import { ref, onMounted, computed } from "vue";
import PostSummary from "@/components/PostSummary.vue";
import { ElDialog, ElForm, ElFormItem, ElInput, ElButton, ElCard, ElMessage } from 'element-plus';
import { useRouter } from "vue-router";
import { post } from "@/api/index";
import { UsernameIcon } from "@layui/icons-vue";

export default {
  name: "ForumPage",
  components: {
    PostSummary,
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
    ElCard
  },
  setup() {
    const sideNav = ref([]);
    const posts = ref([]);
    const userGroups = ref([]);
    const isAddPostDialogVisible = ref(false);
    const isJoinGroupDialogVisible = ref(false);
    const groupCode = ref("");
    const newPost = ref({
      title: '',
      content: '',
      tags: '',
      group: ''
    });
    const isBuildGroupDialogVisible = ref(false);
    const newGroupName = ref("");
    const groupNameLength = computed(() => newGroupName.value.length);
    const newGroupType = ref("public");
    const userLevel = ref(0);
    const userId = ref(0);
    const userName=ref('');
    const router = useRouter();

    onMounted(() => {
      console.log('onMounted');
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then(res => {
          if (res.res == 1) {
            userLevel.value = res.user.admin;
            userId.value = res.user.uid;
            userName.value=res.user.name;
            console.log(userLevel.value);
            fetchUserGroups();
          } else {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem('Authorization');
          }
        });
      }
      fetchDiscussions(userGroups.value[0]?.id, 0);
    });

    async function fetchUserGroups() {
      try {
        console.log("Starting fetchUserGroups..."); // Debugging log
        const publicResponse = await post('/forum/group/get-public-group', {
          pageNum: 1,
          pageSize: 10,
          sort: 'name',
          isAsc: 'true'
        });
        console.log("Public groups response:", publicResponse); // Debugging log

        let groups = [];
        console.log("Set Groups");

        if (publicResponse.res) {
          console.log("Public groups data:", publicResponse.data.records);
          groups = publicResponse.data.records.map(group => ({
            name: group.name,
            isPrivate: false,
            id: group.id 
          }));
        }

        console.log("Public groups processed:", groups);

        if (userId.value) {
          try{
            const privateResponse = await post('/forum/group/get-private-group-by-user', {
            userId: userId.value,
            pageNum: 1,
            pageSize: 10,
            sort: 'name',
            isAsc: 'true'
          });
          console.log("Private groups response:", privateResponse);

          if (privateResponse.data) {
            console.log("Private groups data:", privateResponse.data.records);
            const privateGroups = privateResponse.data.records.map(group => ({
              name: group.name,
              isPrivate: true,
              id: group.id  
            }));
            console.log("Private groups processed:", privateGroups);
            groups = groups.concat(privateGroups);
          }
          }catch(error){
            console.error("Error fetching private groups:", error);
          }
          
        }

        console.log("Groups fetched (before assignment):", groups);
        userGroups.value = groups;
        console.log("Groups assigned to userGroups:", userGroups.value);

        sideNav.value = new Array(groups.length).fill(false);
        if (groups.length > 0) {
          sideNav.value[0] = true;
        }
        console.log("sideNav updated:", sideNav.value);

      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    }

    function createGroup() {
      const groupData = {
        creatorId: userId.value,
        groupName: newGroupName.value,
        description: "Your group description here", // Add a description
        isPrivate: newGroupType.value === 'private' ? 1 : 0
      };

      post('/forum/group/create', groupData)
        .then(response => {
          if (response.res === 1) {
            console.log('Group created successfully');
            fetchUserGroups(); // Refresh the list of groups after creating a new one
          } else {
            console.error('Failed to create group', response.data);
          }
        })
        .catch(error => {
          console.error('Error creating group:', error);
        });

      // Reset dialog state and values after creating the group
      isBuildGroupDialogVisible.value = false;
      newGroupName.value = "";
      newGroupType.value = "public";
    }

    function showBuildGroupDialog() {
      isBuildGroupDialogVisible.value = true;
    }

    function ManageClick() {
      console.log(ManageClick)
      router.push({
        path: "/manageforum",
      });
    }

    async function fetchDiscussions(groupId, index) {
      try {
        const response = await post('/forum/post/get-by-group', {
          groupId: groupId,
          pageNum: 1,
          pageSize: 10,
          sort: 'created_at',
          isAsc: 'false'
        });

        if (response.res === 1) {
          posts.value = response.data.records;
          sideNav.value = new Array(userGroups.value.length).fill(false);
          sideNav.value[index] = true;
        } else {
          ElMessage.error('Failed to fetch discussions');
        }
      } catch (error) {
        console.error('Error fetching discussions:', error);
        ElMessage.error('Error fetching discussions');
      }
    }

    function showAddPostDialog() {
      newPost.value.group = userGroups.value[sideNav.value.findIndex(active => active)];
      isAddPostDialogVisible.value = true;
    }

    async function submitPost() {
      const activeGroup = userGroups.value[sideNav.value.findIndex(active => active)];
      if (!activeGroup) {
        ElMessage.error('Please select a group to post in.');
        return;
      }
      
      const postData = {
        creatorId: userId.value,
        groupId: activeGroup.id,
        title: newPost.value.title,
        content: newPost.value.content,
        tags: newPost.value.tags
      };

      try {
        const response = await post('/forum/post/create', postData);
        if (response.res === 1) {
          ElMessage.success('Post submitted successfully');
          // Optionally, fetch posts again or update the posts list
          fetchDiscussions(activeGroup.id, sideNav.value.findIndex(active => active));
        } else {
          ElMessage.error('Failed to submit post');
        }
      } catch (error) {
        console.error('Error submitting post:', error);
        ElMessage.error('Error submitting post');
      }

      isAddPostDialogVisible.value = false;
      newPost.value.title = '';
      newPost.value.content = '';
      newPost.value.tags = '';
    }

    function joinGroup() {
      const joinData = {
        userId: userId.value,
        groupId: parseInt(groupCode.value)
      };

      post('/forum/group/join', joinData)
        .then(response => {
          if (response.data.res === 1) {
            console.log('Joined group successfully');
            fetchUserGroups();
          } else {
            console.error('Failed to join group');
          }
        })
        .catch(error => {
          console.error('Error joining group:', error);
        });

      isJoinGroupDialogVisible.value = false;
      groupCode.value = "";
    }

    function showJoinGroupDialog() {
      isJoinGroupDialogVisible.value = true;
    }

    async function shareGroup() {
      const activeGroup = userGroups.value[sideNav.value.findIndex(active => active)];
      if (activeGroup) {
        try {
          await navigator.clipboard.writeText(activeGroup.id);
          alert(`Group ID ${activeGroup.id} copied to clipboard!`);
        } catch (error) {
          console.error('Failed to copy group ID:', error);
        }
      }
    }

    return {
      sideNav,
      posts,
      userGroups,
      fetchDiscussions,
      isAddPostDialogVisible,
      newPost,
      showAddPostDialog,
      submitPost,
      isJoinGroupDialogVisible,
      groupCode,
      joinGroup,
      showJoinGroupDialog,
      isBuildGroupDialogVisible,
      newGroupName,
      newGroupType,
      createGroup,
      showBuildGroupDialog,
      userLevel,
      ManageClick,
      shareGroup,
      groupNameLength,
    };
  },
};
</script>



<style scoped>
.user-wrap {
  display: flex;
  width: 1240px;
  margin: 40px auto 60px auto;
  justify-content: space-between;
}
.user-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin:10px;
  flex: 0 0 240px;
  width: 350px;
  min-height: 600px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.user-right {
  flex: 1;
  min-width: 0; /* This ensures the content fits within the parent container */
  width: calc(100% - 350px); /* Ensure it fits within the parent container */
  margin:10px;
  min-height: 580px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Add vertical scroll if needed */
}
.user-title {
  display: block;
  margin-top: 40px;
  width: 100%;
  color: #111880;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins-ExtraBold";
}
.user-nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.user-nav ul {
  padding: 0;
  list-style: none;
}
.user-nav li {
  display: block;
  width: 100%;
  margin: 10px 0;
}
.user-nav li button {
  display: block;
  width: 100%;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  color: #111880;
  font-family: "Poppins-SemiBold";
  background-color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}
.user-nav li button.active,
.user-nav li button:hover {
  color: #ffffff;
  background-color: #5473ff;
}
.new-group {
  text-align: center;
}
.new-group a {
  display: block;
  margin-bottom: 10px;
  color: #5473ff;
  text-decoration: none;
}
.new-group a:hover {
  text-decoration: underline;
}
.content {
  width: 100%;
  padding: 10px;
}
.el-dialog .dialog-footer {
  text-align: right;
}
.user-nav li button.is-private {
  color: #99c1de; /* Lighter text color for private groups */
}

.content button {
  display: inline-block;
  width: auto; /* Adjust width based on content */
  font-size: 16px;
  height: 45px;
  line-height: 45px;
  color: #111880;
  font-family: "Poppins-SemiBold";
  background-color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin: 10px 10px 10px 0; /* Add some margin for spacing */
  padding: 0 20px; /* Add padding for better appearance */
}

.content button:hover {
  color: #ffffff;
  background-color: #5473ff;
}
</style>
