<template>
  <div class="post" @click="goToDetail">
    <h3>{{ post.title }}</h3>
    <div class="post-info">
      <span>Author: {{ post.authorName }}</span>
      <span>Time: {{ post.createdAt }}</span>
      <span>Topic: {{ post.tags}}</span>
    </div>
    <p class="post-description">{{ post.content }}</p>
  </div>
</template>

<script>
export default {
  name: 'PostSummary',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToDetail() {
      sessionStorage.setItem('currentPost', JSON.stringify(this.post));
      this.$router.push('/post');
    }
  }
};
</script>

<style scoped>
.post {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px;  /* Adjusting margin to fit within the container */
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  max-width: 100%; /* Ensure it fits within the parent container */
  cursor: pointer; /* Add cursor pointer to indicate it's clickable */
}
.post:hover {
  background-color: #f9f9f9;
}
.post h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  color: #111880;
  font-family: "Poppins-ExtraBold";
}
.post-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}
.post-info span {
  margin-right: 10px;
}
.post-info span:before {
  content: "•";
  margin-right: 5px;
  color: #ccc;
}
.post-info span:first-child:before {
  content: "";
  margin-right: 0;
}
.post-description {
  margin: 0;
  font-size: 16px;
  color: #666;
  font-family: "Poppins-Regular";
  max-height: 100px; /* Control the maximum height of the content */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Use ellipsis for overflow content */
  display: -webkit-box; /* Combined with the next two lines to control text overflow */
  -webkit-line-clamp: 4; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
